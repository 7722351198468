import { useEffect, useState } from "react";
import { useListContext } from "./ListContext";
import CardContainer from "./CardContainer";
import "./carousel.css";

import img1 from "../images/examples/img1.png";
import img2 from "../images/examples/img2.png";
import img3 from "../images/examples/img3.png";
import img4 from "../images/examples/img4.png";

import { ReactComponent as LeftIcon } from "../images/ic-example-left.svg";
import { ReactComponent as RightIcon } from "../images/ic-example-right.svg";

const ITEMS = [
  {
    imgSrc: img1,
    prompt: "Draw a cute unicorn with rainbow hair in a magical flower field."
  },
  {
    imgSrc: img2,
    prompt: "Draw a brave child riding a dragon through the sky."
  },
  {
    imgSrc: img3,
    prompt:
      "Draw a puppy wearing a Superman suit, flying above a beautiful city."
  },
  {
    imgSrc: img4,
    prompt: "Draw a boy floating in space with a friendly alien next to a UFO!"
  }
];

export default function ExampleCard() {
  return (
    <CardContainer>
      <Carousel items={ITEMS} />
    </CardContainer>
  );
}

const Carousel = ({ items, onPrevClick, onNextClick }: any) => {
  const { currentIndex: currentListIndex, setCurrentImageSrc } =
    useListContext();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (currentListIndex === 0) {
      setCurrentImageSrc(items[currentIndex].imgSrc);
    }
  }, [currentListIndex, currentIndex, items, setCurrentImageSrc]);

  const nextItem = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex(prevIndex => (prevIndex + 1) % items.length);
    }
  };

  const prevItem = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex(
        prevIndex => (prevIndex - 1 + items.length) % items.length
      );
    }
  };

  useEffect(() => {
    const transitionEnd = () => setIsTransitioning(false);
    document.addEventListener("transitionend", transitionEnd);
    return () => document.removeEventListener("transitionend", transitionEnd);
  }, []);

  const getItemStyle = (index: number) => {
    const diff = (index - currentIndex + items.length) % items.length;
    let translateX = 0;
    let scale = 1;
    let zIndex = 1;

    if (diff === 0) {
      translateX = 29;
      zIndex = 2;
    } else if (diff === 1 || diff === -2) {
      translateX = 120;
      scale = 0.7;
    } else if (diff === -1 || diff === 2) {
      translateX = -62; // -(100% - 38%)
      scale = 0.7;
    } else {
      scale = 0;
    }

    return {
      transform: `translateX(${translateX}%) scale(${scale})`,
      zIndex,
      transition: "all 0.3s ease-in-out"
    };
  };

  const getBackgroundStyle = (index: number) => {
    const diff = (index - currentIndex + items.length) % items.length;
    let opacity = 1;

    if (diff !== 0) {
      opacity = 0;
    }

    return {
      opacity,
      transition: "opacity 0.5s ease-in-out"
    };
  };

  const handlePrevClick = () => {
    prevItem();
    if (onPrevClick) onPrevClick();
  };

  const handleNextClick = () => {
    nextItem();
    if (onNextClick) onNextClick();
  };

  return (
    <div className="carousel">
      <div className="carousel-items">
        {items.map((item: any, index: number) => (
          <div
            key={index}
            className="carousel-item"
            style={getItemStyle(index)}
          >
            <div
              className={`carousel-item-bg inset-0 ${
                currentIndex === index ? "animate-spinSlow" : ""
              }`}
              style={getBackgroundStyle(index)}
            />
            <div
              className="carousel-item-content"
              style={currentIndex === index ? undefined : { boxShadow: "none" }}
            >
              <img
                className="rounded-full w-[168px] h-[168px] sm:w-[240px] sm:h-[240px] m-[6px]"
                alt={item.prompt}
                src={item.imgSrc}
              />
            </div>
          </div>
        ))}
        <div className="carousel-grad absolute inset-y-0 w-12 rotate-180" />
        <div className="carousel-grad absolute right-0 inset-y-0 w-12" />
      </div>
      <div className="carousel-bottom">
        <button onClick={handlePrevClick} className="shrink-0 card-button prev">
          <LeftIcon />
        </button>
        <p className="text-center text-[#3D3F40] sm:text-lg">
          {items[currentIndex].prompt || "prompt"}
        </p>
        <button onClick={handleNextClick} className="shrink-0 card-button next">
          <RightIcon />
        </button>
      </div>
    </div>
  );
};
