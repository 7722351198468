import ButtonBase from "./ButtonBase";
import { ReactComponent as StopIcon } from "../../images/ic-stop.svg";
import { ButtonHTMLAttributes } from "react";

export default function StopButton({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  const getClassName = (isPressed: boolean) =>
    isPressed ? "bg-[#4F0FE9] !shadow-none" : "bg-[#7121F8]";

  return (
    <ButtonBase {...props} className="p-[14px]" getClassName={getClassName}>
      <StopIcon />
    </ButtonBase>
  );
}
