import { useState, useRef, useCallback, useEffect } from "react";
import { audioBlobToM4aBlob } from "../utils/audioFormatter";

interface UseAudioVisualizerProps {
  maxRecordingTime?: number;
  width?: number;
  height?: number;
}

export const useAudioVisualizer = ({
  maxRecordingTime = 60000,
  width = 706,
  height = 64
}: UseAudioVisualizerProps = {}) => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [audioData, setAudioData] = useState<number[]>([]);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const [error, setError] = useState<string | null>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const recordingStartTime = useRef<number>(0);
  const duration = useRef<number>(0);

  const dotSize = 6;
  const gap = 8;
  const dotCount = Math.floor(width / (dotSize + gap));

  useEffect(() => {
    return () => {
      // Clean up: Close the AudioContext when the component unmounts
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const drawVisualization = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, width, height);

    if (!recordedBlob) {
      // Draw the initial gray dots
      for (let i = 0; i < dotCount; i++) {
        const x = i * (dotSize + gap);
        ctx.beginPath();
        ctx.arc(x + dotSize / 2, height / 2, dotSize / 2, 0, 2 * Math.PI);
        ctx.fillStyle = "#E3E5E7";
        ctx.fill();
      }
    }

    if (audioData.length > 0) {
      let columnsToDraw = isRecording ? audioData.slice(-dotCount) : audioData;
      let startX = 0;

      if (!isRecording) {
        const totalColumns = columnsToDraw.length;
        const maxColumnsInView = dotCount;

        if (totalColumns > maxColumnsInView) {
          const columnsToSkip = totalColumns - maxColumnsInView;
          const indicesToSkip = new Set<number>();
          const interval = totalColumns / columnsToSkip;

          for (let i = 0; i < columnsToSkip; i++) {
            indicesToSkip.add(Math.floor(i * interval));
          }

          columnsToDraw = columnsToDraw.filter(
            (_, index) => !indicesToSkip.has(index)
          );
        } else if (totalColumns < maxColumnsInView) {
          startX = Math.floor(
            ((maxColumnsInView - totalColumns) * (dotSize + gap)) / 2
          );
        }
      }

      let playedIndex = 0;
      if (recordedBlob) {
        const playedRatio = currentTime / duration.current;
        playedIndex = Math.floor(playedRatio * columnsToDraw.length);
      }

      columnsToDraw.forEach((value, index) => {
        const columnHeight = Math.max(dotSize, value * height * 1.8);
        const x = startX + index * (dotSize + gap);
        const y = (height - columnHeight) / 2;

        ctx.beginPath();
        ctx.roundRect(x, y, dotSize, columnHeight, dotSize / 2);

        if (recordedBlob) {
          ctx.fillStyle = index <= playedIndex ? "#141416" : "#E3E5E7";
        } else if (isRecording) {
          ctx.fillStyle = "#141416";
        }
        ctx.fill();
      });
    }
  }, [
    audioData,
    currentTime,
    width,
    height,
    dotCount,
    dotSize,
    gap,
    isRecording,
    recordedBlob
  ]);

  useEffect(() => {
    drawVisualization();
  }, [drawVisualization]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      const mediaRecorder = mediaRecorderRef.current;

      mediaRecorder.ondataavailable = async (e: BlobEvent) => {
        const audioBlob = new Blob([e.data], {
          type: e.data.type || "audio/webm"
        });

        const m4aBlob = await audioBlobToM4aBlob(
          audioBlob,
          audioContextRef.current!
        );
        setRecordedBlob(m4aBlob);

        const audioUrl = URL.createObjectURL(audioBlob);
        if (audioRef.current) {
          audioRef.current.src = audioUrl;
        }
      };

      mediaRecorder.stop();
      setIsRecording(false);

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      const elapsedRecordingTime =
        (Date.now() - recordingStartTime.current) / 1000;
      duration.current = Math.min(
        elapsedRecordingTime,
        maxRecordingTime / 1000
      );
    }
  }, [isRecording, maxRecordingTime]);

  const startRecording = useCallback(() => {
    if (typeof MediaRecorder === "undefined") {
      setError("이 브라우저에서는 녹음을 지원하지 않아요.");
      return;
    }

    setRecordedBlob(null);
    setAudioData([]);
    setCurrentTime(0);
    duration.current = 0;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        audioContextRef.current = new AudioContext();
        const audioContext = audioContextRef.current;

        // Check if the AudioContext needs to be resumed due to Safari's autoplay policy
        if (audioContext.state === "suspended") {
          audioContext.resume();
        }

        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();

        recordingStartTime.current = Date.now();

        const source = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;
        source.connect(analyser);

        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
          analyser.getByteFrequencyData(dataArray);
          const average = dataArray.reduce((a, b) => a + b) / bufferLength;
          setAudioData(prevData => {
            return [...prevData, average / 255];
          });
        }, 100);

        setIsRecording(true);

        setTimeout(() => stopRecording(), maxRecordingTime);
      })
      .catch(error => {
        console.error("Error accessing microphone:", error);
        setError("마이크를 사용할 수 없어요.\n권한을 확인해 주세요.");
      });
  }, [maxRecordingTime, stopRecording]);

  const playAudio = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
        if (audioRef.current) {
          setCurrentTime(audioRef.current.currentTime);
        }
      }, 1000 / 60);
    }
  }, []);

  const pauseAudio = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, []);

  const handleAudioEnded = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return {
    isRecording,
    isPlaying,
    recordedBlob,
    canvasRef,
    audioRef,
    error,
    startRecording,
    stopRecording,
    playAudio,
    pauseAudio,
    handleAudioEnded
  };
};
