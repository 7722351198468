import { useCallback, useEffect, useRef, useState } from "react";
import { usePopup } from "./components/PopupContext";
import { useToken } from "./components/TokenContext";

import AudioVisualizer from "./components/AudioVisualizer";
import GuideTextView from "./components/GuideTextView";
import NextButton from "./components/buttons/NextButton";
import CheckButton from "./components/buttons/CheckButton";
import RerecordButton from "./components/buttons/RerecordButton";
import CloseButton from "./components/buttons/CloseButton";
import HintButton from "./components/buttons/HintButton";
import RecordButton from "./components/buttons/RecordButton";

import recordCharacterA from "./images/panel/img-record-a.png";
import idleCharacterA from "./images/panel/img-play-a.png";
import confirmCharacterA from "./images/panel/img-confirm-a.png";
import recordCharacterB from "./images/panel/img-record-b.png";
import idleCharacterB from "./images/panel/img-play-b.png";
import confirmCharacterB from "./images/panel/img-confirm-b.png";
import recordCharacterC from "./images/panel/img-record-c.png";
import idleCharacterC from "./images/panel/img-play-c.png";
import confirmCharacterC from "./images/panel/img-confirm-c.png";
import recordCharacterD from "./images/panel/img-record-d.png";
import idleCharacterD from "./images/panel/img-play-d.png";
import confirmCharacterD from "./images/panel/img-confirm-d.png";
import { ReactComponent as BubbleImage } from "./images/img-bubble-countdown.svg";

export type DrawingPanelStatus =
  | "READY"
  | "COUNTDOWN"
  | "RECORDING"
  | "RECORDED"
  | "CONFIRM";

interface DrawingPanelProps {
  close: () => void;
  onConfirm: (prompt: string) => void;
  isVisible?: boolean;
  defaultPrompt?: string;
}

const getRandomHint = (current: string) => {
  const hints = [
    "Draw a dog with a _____ on its head.",
    "Draw a monkey eating a _____.",
    "Draw a _____ flying in the sky.",
    "Draw a fish swimming next to a _____.",
    "Draw a _____ with a big smile.",
    "Draw a dinosaur wearing a _____ around its neck.",
    "Draw an elephant holding a _____ with its trunk.",
    "Draw a butterfly landing on a _____.",
    "Draw a giraffe eating a _____ from a tree.",
    "Draw a car shaped like a _____.",
    "Draw a dragon breathing out _____.",
    "Draw a turtle carrying a _____ on its back.",
    "Draw a unicorn with a _____ on its horn.",
    "Draw a rabbit jumping over a _____."
  ];
  while (true) {
    const hint = hints[Math.floor(Math.random() * hints.length)];
    if (hint !== current) return hint;
  }
};

const getRandomCharacterSet = () => {
  const characters = [
    {
      record: recordCharacterA,
      idle: idleCharacterA,
      confirm: confirmCharacterA
    },
    {
      record: recordCharacterB,
      idle: idleCharacterB,
      confirm: confirmCharacterB
    },
    {
      record: recordCharacterC,
      idle: idleCharacterC,
      confirm: confirmCharacterC
    },
    {
      record: recordCharacterD,
      idle: idleCharacterD,
      confirm: confirmCharacterD
    }
  ];
  return characters[Math.floor(Math.random() * characters.length)];
};

export default function DrawingPanel({
  close,
  onConfirm,
  isVisible = false,
  defaultPrompt
}: DrawingPanelProps) {
  const { utoken } = useToken();

  const [status, setStatus] = useState<DrawingPanelStatus>(
    defaultPrompt ? "CONFIRM" : "READY"
  );
  const [countdown, setCountdown] = useState(3);
  const [prompt, setPrompt] = useState(defaultPrompt);
  const [hint, setHint] = useState<string>();

  const [shouldRender, setShouldRender] = useState(false);
  const [animationClass, setAnimationClass] = useState(
    "opacity-0 scale-75 translate-y-full"
  );

  const { openPopup } = usePopup();

  const recordedBlob = useRef<Blob | null>(null);
  const characterSet = useRef<any>();

  useEffect(() => {
    if (isVisible) {
      characterSet.current = getRandomCharacterSet();
      setStatus(defaultPrompt ? "CONFIRM" : "READY");
      setShouldRender(true);
      // Trigger the animation after a short delay to ensure the initial class is applied
      setTimeout(
        () => setAnimationClass("opacity-100 scale-100 translate-y-0"),
        50
      );
    } else {
      setAnimationClass("opacity-0 scale-75 translate-y-full");
      const timer = setTimeout(() => setShouldRender(false), 450);
      return () => clearTimeout(timer);
    }
  }, [isVisible, defaultPrompt]);

  const startCountdown = useCallback(() => {
    setCountdown(3);
    const interval = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setStatus("RECORDING");
    }, 3000);
  }, []);

  useEffect(() => {
    if (status === "COUNTDOWN") {
      startCountdown();
    }
  }, [status, startCountdown]);

  const showHint = () => {
    setHint(prev => getRandomHint(prev || ""));
  };

  const renderTexts = () => {
    switch (status) {
      case "READY":
      case "COUNTDOWN":
      case "RECORDING":
        return <GuideTextView>{hint || "영어로 말해요"}</GuideTextView>;
      case "RECORDED":
        return <GuideTextView>녹음이 잘 되었는지 들어보세요</GuideTextView>;
      case "CONFIRM":
        return prompt != null ? (
          <>
            <GuideTextView>
              {defaultPrompt ? "이대로 그릴까요?" : "제가 잘 알아들었나요?"}
            </GuideTextView>
            <div className="min-h-[156px] flex items-center max-h-[50%] overflow-hidden max-w-[706px] mx-3 sm:mx-auto justify-center text-center text-3xl sm:text-4xl sm:leading-[52px] text-[#141416]">
              {prompt}
            </div>
          </>
        ) : (
          <GuideTextView>말한 내용을 적고 있어요</GuideTextView>
        );
    }
  };

  const renderButtons = () => {
    switch (status) {
      case "READY":
        return (
          <div className="flex items-center justify-center gap-5">
            <HintButton onClick={showHint} />
            <RecordButton onClick={() => setStatus("COUNTDOWN")} />
          </div>
        );
      case "CONFIRM":
        return prompt != null ? (
          <div className="flex items-center justify-center gap-5">
            <RerecordButton onClick={() => setStatus("COUNTDOWN")} />
            <CheckButton onClick={() => onConfirm(prompt)} />
          </div>
        ) : null;
      default:
        return null;
    }
  };

  const getCharacterSrc = useCallback(() => {
    switch (status) {
      case "READY":
      case "RECORDED":
        return characterSet.current.idle;
      case "COUNTDOWN":
      case "RECORDING":
        return characterSet.current.record;
      case "CONFIRM":
        return characterSet.current.confirm;
    }
  }, [characterSet, status]);

  const onRecorded = useCallback((blob: Blob) => {
    recordedBlob.current = blob;
    setStatus("RECORDED");
  }, []);

  const onError = useCallback(
    (error: string) => {
      openPopup("audio", { body: error }, () => {
        setStatus("READY");
      });
    },
    [openPopup]
  );

  const speechToText = async () => {
    const blob = recordedBlob.current;
    if (!blob) return;

    setPrompt(undefined);
    setStatus("CONFIRM");

    const formData = new FormData();
    formData.append("raw_voice_file", blob, "output.mp4");
    if (utoken) {
      formData.append("utoken", utoken);
    }

    fetch("/api/voices/", {
      method: "POST",
      headers: {
        Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`
      },
      body: formData
    })
      .then(res => res.json())
      .then(data => {
        if (!data.stt_text) {
          onError("녹음이 잘못된 것 같아요.\n큰 목소리로 다시 말해보세요.");
          return;
        }

        localStorage.setItem("stt_code", data.code);
        setPrompt(data.stt_text);
      })
      .catch(err => {
        console.error(err);
        onError("녹음이 잘못된 것 같아요.\n큰 목소리로 다시 말해보세요.");
      });
  };

  if (!shouldRender) return null;

  return (
    <div
      className={`fixed inset-0 bg-white text-center z-20 pt-[25%] sm:pt-16 transform transition-all duration-[450ms] ease-in-out origin-bottom ${animationClass}`}
      style={{ opacity: isVisible ? 1 : 0 }}
    >
      <div>{renderTexts()}</div>
      <div className="mt-9">{renderButtons()}</div>
      {status === "COUNTDOWN" && (
        <div className="relative w-full sm:w-[800px] sm:mx-auto">
          <BubbleImage className="hidden sm:block" />
          <span className="absolute top-[15px] inset-x-0 text-center font-black text-[128px] text-[#7121F8]">
            {countdown}
          </span>
        </div>
      )}
      {(status === "RECORDING" || status === "RECORDED") && (
        <div>
          <AudioVisualizer
            onRecorded={onRecorded}
            onError={onError}
            retry={() => setStatus("COUNTDOWN")}
            width={window.innerWidth > 639 ? 706 : window.innerWidth * 0.8}
            height={64}
            maxRecordingTime={30000}
          />
        </div>
      )}
      <div className="absolute bottom-0 inset-x-0 sm:left-1/2 sm:-translate-x-1/2">
        <img alt="character" src={getCharacterSrc()} />
      </div>
      <div className="absolute flex justify-between bottom-6 inset-x-8 sm:bottom-12 sm:inset-x-16">
        {!["COUNTDOWN", "RECORDING"].includes(status) && (
          <CloseButton onClick={close} />
        )}
        {status === "RECORDED" && <NextButton onClick={speechToText} />}
      </div>
    </div>
  );
}
