export default function CardContainer({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="relative rounded-3xl card-shadow-outer w-[300px] sm:w-[432px] h-[400px] sm:h-[544px] bg-[#E3E5E7]">
      <div className="absolute rounded-[20px] card-shadow-inner inset-x-0 top-0 bottom-1 bg-[#F9F9FC]">
        {children}
      </div>
    </div>
  );
}
