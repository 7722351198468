import React, { createContext, useContext, useEffect, useState } from "react";

interface ListContextType {
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  currentImageSrc: string | null;
  setCurrentImageSrc: (src: string | null) => void;
  totalItems: number;
}

const ListContext = createContext<ListContextType | undefined>(undefined);

interface ListProviderProps {
  children: React.ReactNode;
  totalItems: number;
}

export const ListProvider: React.FC<ListProviderProps> = ({
  children,
  totalItems
}) => {
  const [currentIndex, setCurrentIndex] = useState(totalItems > 1 ? 1 : 0);
  const [currentImageSrc, setCurrentImageSrc] = useState<string | null>(null);

  useEffect(() => {
    setCurrentIndex(totalItems > 1 ? 1 : 0);
  }, [totalItems]);

  return (
    <ListContext.Provider
      value={{
        currentIndex,
        setCurrentIndex,
        currentImageSrc,
        setCurrentImageSrc,
        totalItems
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export const useListContext = () => {
  const context = useContext(ListContext);
  if (context === undefined) {
    throw new Error("useListContext must be used within a ListProvider");
  }
  return context;
};
