import { ButtonHTMLAttributes, useEffect, useState } from "react";

export interface ButtonBaseProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  getClassName: (isPressed: boolean) => string;
}

export default function ButtonBase({
  children,
  className,
  getClassName,
  ...props
}: ButtonBaseProps) {
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    const handleGlobalMouseUp = () => setIsPressed(false);
    document.addEventListener("mouseup", handleGlobalMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, []);

  const handleMouseDown = () => setIsPressed(true);
  const handleMouseUp = () => setIsPressed(false);
  const handleTouchStart = () => setIsPressed(true);
  const handleTouchEnd = () => setIsPressed(false);

  return (
    <button
      {...props}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      className={`rounded-full ${
        isPressed ? "button-shadow-pressed" : "button-shadow"
      } ${className} ${getClassName(isPressed)}`}
    >
      {children}
    </button>
  );
}
