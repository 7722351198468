import ButtonBase from "./ButtonBase";
import { ReactComponent as MicIcon } from "../../images/icon-mic.svg";
import { ButtonHTMLAttributes } from "react";

export default function RecordButton({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  const getClassName = (isPressed: boolean) =>
    isPressed
      ? "bg-[#4F0FE9] border border-[#4801BC] !shadow-none"
      : "bg-[#7121F8]";

  return (
    <ButtonBase
      {...props}
      className="text-white w-[160px] sm:w-[199px] pt-[7px] pb-[9px] px-2 sm:pt-[11px] sm:pb-[13px] sm:px-3"
      getClassName={getClassName}
    >
      <div className="flex gap-1 sm:gap-3 items-center font-bold sm:text-lg">
        <MicIcon className="w-12 h-12 sm:w-[56px] sm:h-[56px]" fill="white" />
        말하기 시작
      </div>
    </ButtonBase>
  );
}
