import { useEffect, useRef, useState } from "react";

interface GuideTextViewProps {
  children: string;
}

export default function GuideTextView({ children }: GuideTextViewProps) {
  const [currentText, setCurrentText] = useState<string | null>(null);
  const prevText = useRef<string | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentText(prev => {
      if (prev === children) return prev;
      prevText.current = prev;
      return children;
    });
  }, [children]);

  useEffect(() => {
    if (currentText && currentText !== prevText.current) {
      const p = document.createElement("p");
      p.textContent = currentText;
      p.classList.add(
        "text-center",
        "absolute",
        "inset-x-2",
        "sm:inset-x-0",
        "whitespace-pre-line",
        "h-auto"
      );
      if (prevText.current) {
        p.classList.add("animate-promptIn", "-translate-y-full");
        p.onanimationstart = () => {
          containerRef.current?.firstElementChild?.classList.add(
            "animate-promptOut"
          );
        };
        p.onanimationend = () => {
          if (containerRef.current?.childElementCount === 2) {
            containerRef.current?.removeChild(
              containerRef.current?.firstChild!
            );
          }
        };
      }
      containerRef.current?.appendChild(p);
    }
  }, [currentText]);

  return (
    <div
      className="text-gray relative overflow-hidden text-3xl sm:text-4xl font-bold h-[72px] sm:h-[52px] sm:leading-[52px]"
      ref={containerRef}
    ></div>
  );
}
