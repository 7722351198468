import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { handleTokens } from "../utils/tokens";

type TokenContextType = {
  utoken: string | null;
};

const TokenContext = createContext<TokenContextType | undefined>(undefined);

export const TokenProvider = ({ children }: { children: ReactNode }) => {
  const [utoken, setUtoken] = useState<string | null>(null);
  const ftokenRef = useRef<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const ftoken = searchParams.get("ftoken");

    if (ftoken) {
      if (ftokenRef.current === ftoken) {
        console.log("Already handled ftoken");
        return;
      }

      ftokenRef.current = ftoken;

      handleTokens(ftoken).then(tokens => {
        if (tokens) {
          setUtoken(tokens.utoken);
        }
      });
    } else {
      console.error("No ftoken found in URL");
    }
  }, []);

  return (
    <TokenContext.Provider value={{ utoken }}>{children}</TokenContext.Provider>
  );
};

export const useToken = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error("useToken must be used within a TokenProvider");
  }
  return context;
};
