import { useEffect, useState } from "react";
import { usePopup } from "./components/PopupContext";
import { useToken } from "./components/TokenContext";
import { ListProvider, useListContext } from "./components/ListContext";
import DrawingCard from "./components/DrawingCard";
import ExampleCard from "./components/ExampleCard";
import DrawingPanel from "./DrawingPanel";
import DrawButton from "./components/DrawButton";
import HorizontalList from "./components/HorizontalList";
import FullscreenImage from "./components/FullscreenImage";

import { ReactComponent as Tip } from "./images/tip.svg";
import loadingBg from "./images/loading-bg.svg";

type DrawPanelOptions = {
  isShowing: boolean;
  defaultPrompt?: string;
};

export default function Main() {
  const { utoken } = useToken();

  const savedDrawings = localStorage.getItem("drawings");
  const [drawings, setDrawings] = useState<any[]>(
    savedDrawings ? JSON.parse(savedDrawings) : []
  );
  const [drawingText, setDrawingText] = useState<string | null>(null);

  const [drawPanelOptions, setDrawPanelOptions] = useState<DrawPanelOptions>({
    isShowing: false
  });

  const drawingsToRender = drawingText
    ? [{ prompt: drawingText }].concat(drawings)
    : drawings;

  const [showTip, setShowTip] = useState(drawingsToRender.length === 0);
  const [fullscreenImgSrc, setFullscreenImgSrc] = useState<string | null>(null);

  const { openPopup } = usePopup();

  useEffect(() => {
    if (drawingsToRender.length > 0) {
      setShowTip(false);
    }
  }, [drawingsToRender]);

  const openDrawPanel = (defaultPrompt?: string) => {
    setDrawPanelOptions({
      isShowing: true,
      defaultPrompt
    });
  };

  const closeDrawPanel = () => {
    setDrawPanelOptions({ isShowing: false });
  };

  const requestTextToImage = async (timeout: number) => {
    const stt_code = localStorage.getItem("stt_code");
    if (!stt_code) {
      console.error("stt_code not found");
      setDrawingText(null);
      openPopup("image", {
        body: "그림 그리기에 실패했어요.\n다시 시도해 주세요."
      });
      return;
    }

    let requestCode = stt_code;

    if (drawings.find(d => d.code === stt_code)) {
      // if the drawing already exists, request a new node first.
      requestCode = await fetch(`/api/voices/regenerate/`, {
        method: "POST",
        headers: {
          Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ code: stt_code, utoken })
      })
        .then(res => res.json())
        .then(data => data.code);

      if (!requestCode) {
        console.error("failed to regenerate");
        setDrawingText(null);
        openPopup("image", {
          body: "그림 그리기에 실패했어요.\n다시 시도해 주세요."
        });
        return;
      }

      localStorage.setItem("stt_code", requestCode);
    }

    setTimeout(() => {
      fetch(`/api/voices/code/${requestCode}/?utoken=${utoken}`, {
        method: "GET",
        headers: {
          Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`
        }
      })
        .then(res => res.json())
        .then(data => {
          const {
            id,
            code,
            stt_text,
            response_text,
            response_message,
            generated_image_url,
            created_at
          } = data;

          if (generated_image_url) {
            setDrawingText(null);

            const _drawings = [
              {
                id,
                code,
                prompt: stt_text,
                responseText: response_text,
                createdAt: created_at
              },
              ...drawings
            ];
            localStorage.setItem("drawings", JSON.stringify(_drawings));

            _drawings[0].imageUrl = generated_image_url;
            setDrawings(_drawings);
          } else if (response_message) {
            throw new Error(response_message);
          } else {
            requestTextToImage(5000);
          }
        })
        .catch(err => {
          console.error(err);
          setDrawingText(null);
          openPopup("image", {
            body: err.message?.includes("policy")
              ? "그릴 수 없는 그림이에요.\n다시 시도해 주세요."
              : "그림 그리기에 실패했어요.\n다시 시도해 주세요."
          });
        });
    }, timeout);
  };

  const onConfirmPrompt = (prompt: string) => {
    closeDrawPanel();
    setDrawingText(prompt);
    requestTextToImage(10000);
  };

  const redraw = (drawing: any) => {
    if (drawingText != null) {
      console.error("Cannot redraw while in progress");
      openPopup("image", {
        body: "아직 그림을 그리고 있어요.\n나중에 다시 시도해 주세요."
      });
      return;
    }

    localStorage.setItem("stt_code", drawing.code);
    openDrawPanel(drawing.prompt);
  };

  const showImageFullscreen = (imageUrl?: string) => {
    setFullscreenImgSrc(imageUrl || null);
  };

  const closeFullscreenImage = () => {
    setFullscreenImgSrc(null);
  };

  const closeTip = () => {
    setShowTip(false);
  };

  return (
    <ListProvider totalItems={drawingsToRender.length + 1}>
      <div className="absolute inset-0">
        <CurrentImageBg />
        <div className="absolute inset-0 object-cover w-full bg-blur" />
      </div>
      <div className="absolute inset-0 flex flex-col items-center justify-center overflow-hidden">
        <HorizontalList key={drawingsToRender.length}>
          <ExampleCard />
          {drawingsToRender.map((drawing: any, index: number) => (
            <DrawingCard
              key={index}
              index={index + 1}
              drawing={drawing}
              redraw={redraw}
              showImageFullscreen={showImageFullscreen}
              isRedrawable={drawingText == null}
            />
          ))}
        </HorizontalList>
        <div className="mt-9 relative">
          <DrawButton
            isLoading={drawingText != null}
            onClick={() => openDrawPanel()}
          />
          <button
            onClick={closeTip}
            className={`${
              showTip ? "opacity-100 animate-float" : "opacity-0 -z-10"
            } transition-opacity absolute bottom-[76px] left-1/2 -translate-x-1/2`}
          >
            <Tip />
          </button>
        </div>
      </div>
      <FullscreenImage src={fullscreenImgSrc} close={closeFullscreenImage} />
      <DrawingPanel
        key={drawPanelOptions.defaultPrompt}
        close={closeDrawPanel}
        onConfirm={onConfirmPrompt}
        isVisible={drawPanelOptions.isShowing}
        defaultPrompt={drawPanelOptions.defaultPrompt}
      />
    </ListProvider>
  );
}

const CurrentImageBg = () => {
  const { currentImageSrc } = useListContext();

  return (
    <img
      src={currentImageSrc || loadingBg}
      alt="bg"
      className="absolute inset-0 w-full h-full object-cover blur-sm"
    />
  );
};
