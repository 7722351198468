import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useRef
} from "react";

type PopupContent = {
  title?: string;
  body: string;
};
type PopupContextType = {
  isOpen: boolean;
  content?: PopupContent;
  type: "audio" | "image";
  openPopup: (
    type: "audio" | "image",
    content: PopupContent,
    onClose?: () => void
  ) => void;
  closePopup: () => void;
};

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const PopupProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<PopupContent>();
  const [type, setType] = useState<"audio" | "image">("image");

  const onCloseRef = useRef<() => void>(() => {});

  const openPopup = useCallback(
    (
      popupType: "audio" | "image",
      popupContent: PopupContent,
      onClose?: () => void
    ) => {
      if (isOpen) return;

      setType(popupType);
      setContent(popupContent);
      setIsOpen(true);
      onClose && (onCloseRef.current = onClose);
    },
    [isOpen]
  );

  const closePopup = useCallback(() => {
    setIsOpen(false);
    onCloseRef.current();
  }, []);

  return (
    <PopupContext.Provider
      value={{ isOpen, content, type, openPopup, closePopup }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = (): PopupContextType => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("usePopup must be used within a PopupProvider");
  }
  return context;
};
