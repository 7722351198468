import ButtonBase from "./ButtonBase";
import { ReactComponent as MicIcon } from "../../images/icon-mic.svg";
import { ButtonHTMLAttributes } from "react";

export default function RerecordButton({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  const getClassName = (isPressed: boolean) =>
    isPressed ? "bg-[#F5F7FA] !shadow-none" : "bg-white";

  return (
    <ButtonBase
      {...props}
      className="text-[#141416] w-[199px] pt-[11px] pb-[13px] px-3"
      getClassName={getClassName}
    >
      <div className="flex gap-3 items-center font-bold text-lg">
        <MicIcon width={56} height={56} fill="#141416" />
        다시 말하기
      </div>
    </ButtonBase>
  );
}
