import ButtonBase from "./ButtonBase";
import { ReactComponent as CloseIcon } from "../../images/icon-cancel-l.svg";
import { ButtonHTMLAttributes } from "react";

export default function CloseButton({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  const getClassName = (isPressed: boolean) =>
    isPressed ? "bg-[#D62E28]" : "bg-[#F3403C]";

  return (
    <ButtonBase
      {...props}
      className="sm:w-auto sm:h-auto w-16 h-16 sm:p-6 border border-[#F3403C]"
      getClassName={getClassName}
    >
      <CloseIcon className="w-10 h-10 sm:w-auto sm:h-auto mx-auto" />
    </ButtonBase>
  );
}
