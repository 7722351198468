import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { usePopup } from "./PopupContext";
import OkButton from "./buttons/OkButton";

const imageNames = {
  audio: [
    "img-error-record-01.png",
    "img-error-record-02.png",
    "img-error-record-03.png",
    "img-error-record-04.png",
    "img-error-record-05.png",
    "img-error-record-06.png",
    "img-error-record-07.png",
    "img-error-record-08.png"
  ],
  image: [
    "img-error-01.png",
    "img-error-02.png",
    "img-error-03.png",
    "img-error-04.png",
    "img-error-05.png",
    "img-error-06.png",
    "img-error-07.png",
    "img-error-08.png"
  ]
};

const Popup: React.FC = () => {
  const { isOpen, type, content, closePopup } = usePopup();
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      const randomIndex = Math.floor(Math.random() * imageNames[type].length);
      const randomImageName = imageNames[type][randomIndex];

      import(`../images/popup/${randomImageName}`)
        .then(image => setImageSrc(image.default))
        .catch(err => {
          console.error("Error loading image:", err);
        });
    }
  }, [type, isOpen]);

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const containerVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: "424px" }
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1 }
  };

  const wrapperVariants = {
    hidden: { opacity: 1 },
    visible: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: 0.2, ease: [0.3, 0, 1, 1] } }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 flex justify-center bg-white/70 items-center z-50"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={wrapperVariants}
        >
          <motion.div
            className="absolute inset-0 bg-blur -z-10"
            variants={overlayVariants}
            transition={{ duration: 0.25, ease: "easeInOut" }}
          />
          <div className="pb-6 text-center" onClick={e => e.stopPropagation()}>
            <motion.div
              className="w-52 sm:w-[256px] mx-auto -mb-[104px] aspect-square rounded-full p-[10px] bg-white"
              variants={imageVariants}
              transition={{ duration: 0.25, ease: "easeInOut" }}
            >
              {imageSrc && (
                <img alt="error" src={imageSrc} className="rounded-full" />
              )}
            </motion.div>
            <motion.div
              className="pt-[148px] sm:pt-[136px] text-center rounded-t-[160px] rounded-b-[80px] w-[90vw] sm:w-[430px] bg-white"
              variants={containerVariants}
              transition={{ duration: 0.25, ease: "easeInOut" }}
            >
              <p className="text-center font-bold text-2xl sm:text-[28px] sm:leading-10 whitespace-pre-line">
                {content?.title || "앗, 문제가 생겼어요!"}
              </p>
              <p className="mt-3 sm:text-lg text-center whitespace-pre-line px-3 mb-10">
                {content?.body ||
                  "알 수 없는 문제가 발생했어요.\n다시 시도해 보세요."}
              </p>
              <OkButton onClick={closePopup} />
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Popup;
