import Popup from "./components/Popup";
import { PopupProvider } from "./components/PopupContext";
import { TokenProvider } from "./components/TokenContext";
import Main from "./Main";

function App() {
  return (
    <TokenProvider>
      <PopupProvider>
        <div>
          <Main />
        </div>
        <Popup />
      </PopupProvider>
    </TokenProvider>
  );
}

export default App;
