import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";

import { ReactComponent as BrushIcon } from "../images/icon-brush.svg";
import loadingDots from "../images/lottie/three-dot-slow.json";

interface DrawButtonProps {
  isLoading: boolean;
  onClick: () => void;
  className?: string;
}

export default function DrawButton({
  isLoading,
  onClick,
  className
}: DrawButtonProps) {
  const [showIcon, setShowIcon] = useState(!isLoading);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setShowIcon(true);
      setTimeout(() => setAnimate(true), 50); // Delay to ensure the icon is rendered before animation
    } else {
      setAnimate(false);
      setShowIcon(false);
    }
  }, [isLoading]);

  return (
    <button
      className={className}
      disabled={isLoading}
      onClick={isLoading ? undefined : onClick}
    >
      <div className="relative draw-button-shadow w-[180px] h-[84px] rounded-full bg-white">
        <div className="absolute inset-[6px] rounded-full overflow-hidden">
          <div
            className={`absolute inset-0 draw-button-${
              isLoading ? "disabled" : "gradient"
            }`}
          />
        </div>
        <div className="flex items-center justify-center absolute inset-[10px] rounded-full bg-white">
          {isLoading ? (
            <Lottie animationData={loadingDots} />
          ) : (
            showIcon && (
              <BrushIcon
                className={`transition-all duration-300 ease-in-out ${
                  animate
                    ? "opacity-100 rotate-0 scale-100"
                    : "opacity-0 -rotate-45 scale-75"
                }`}
              />
            )
          )}
        </div>
      </div>
    </button>
  );
}
