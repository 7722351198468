import ButtonBase from "./ButtonBase";
import { ButtonHTMLAttributes } from "react";

export default function OkButton({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  const getClassName = (isPressed: boolean) =>
    isPressed
      ? "bg-[#4F0FE9] border border-[#4801BC] !shadow-none"
      : "bg-[#7121F8]";

  return (
    <ButtonBase
      {...props}
      className="text-white w-[256px] pt-[20px] pb-[22px] sm:pt-[29px] sm:pb-[31px]"
      getClassName={getClassName}
    >
      <div className="font-bold text-center sm:leading-5 sm:text-lg">
        알겠어요
      </div>
    </ButtonBase>
  );
}
