type Tokens = {
  utoken: string;
  ftoken: string;
};

export const handleTokens = async (ftoken: string): Promise<Tokens | null> => {
  let tokens = getStoredTokens();
  if (tokens && tokens.ftoken === ftoken) {
    console.log("Already registered with server");
    return tokens;
  }

  const utoken = generateUUID();
  const registered = await registerUser(utoken, ftoken);
  if (registered) {
    storeTokens(utoken, ftoken);
    return { utoken, ftoken };
  }

  console.error("Failed to register ID with server");
  return null;
};

const registerUser = async (
  utoken: string,
  ftoken: string
): Promise<boolean> => {
  try {
    const response = await fetch("/api/voices/user/", {
      method: "POST",
      headers: {
        Authorization: `Api-Key ${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ utoken, ftoken })
    });
    if (!response.ok) throw new Error("Failed to register ID");
    return true;
  } catch (error) {
    console.error("Error registering ID:", error);
    return false;
  }
};

const generateUUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const storeTokens = (utoken: string, ftoken: string): void => {
  localStorage.setItem("tokens", JSON.stringify({ utoken, ftoken }));
};

const getStoredTokens = (): Tokens | null => {
  const storedTokens = localStorage.getItem("tokens");
  if (storedTokens) {
    const tokens = JSON.parse(storedTokens);
    return tokens;
  }
  return null;
};
