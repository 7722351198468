import ButtonBase from "./ButtonBase";
import { ReactComponent as HintIcon } from "../../images/icon-idea.svg";
import { ButtonHTMLAttributes } from "react";

export default function HintButton({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  const getClassName = (isPressed: boolean) =>
    isPressed ? "!shadow-none" : "";

  return (
    <ButtonBase
      {...props}
      className="border border-[#3D3F40] bg-[#141416] text-white px-3 py-2 w-[120px] sm:w-[154px] sm:px-4 sm:py-3"
      getClassName={getClassName}
    >
      <div className="flex gap-1 sm:gap-3 items-center font-bold sm:text-lg">
        <HintIcon className="w-12 h-12 sm:w-[56px] sm:h-[56px]" />
        힌트
      </div>
    </ButtonBase>
  );
}
