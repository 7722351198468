import imageCompression from "browser-image-compression";

interface CompressionOptions {
  maxSizeMB: number;
  maxWidthOrHeight: number;
  useWebWorker: boolean;
}

export const compressImage = async (imageBlob: Blob): Promise<Blob | null> => {
  const options: CompressionOptions = {
    maxSizeMB: 0.5, // Set the maximum file size
    maxWidthOrHeight: 512, // Set the maximum width or height
    useWebWorker: true
  };

  try {
    // Convert Blob to File object
    const imageFile = new File([imageBlob], "image.png", {
      type: imageBlob.type
    });

    const compressedFile = await imageCompression(imageFile, options);

    // Convert the compressed File back to a Blob
    return new Blob([compressedFile], { type: compressedFile.type });
  } catch (error) {
    console.error("Error compressing image:", error);
    return null;
  }
};
