import React, { useState, useRef, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { useSwipeable, SwipeableHandlers } from "react-swipeable";

import { ReactComponent as LeftIcon } from "../images/ic-arrow-left.svg";
import { ReactComponent as RightIcon } from "../images/ic-arrow-right.svg";
import { useListContext } from "./ListContext";

interface HorizontalListProps {
  children: React.ReactNode[];
  setBgImgSrc?: (src: string | null) => void;
}

const ITEM_WIDTH = {
  mobile: 300,
  tablet: 432
};
const ITEM_GAP = { mobile: 10, tablet: 20 };
const SPRING_MOVEMENT = 450;

const HorizontalList: React.FC<HorizontalListProps> = ({ children }) => {
  const items = children.flat() || [];
  const { currentIndex, setCurrentIndex, totalItems } = useListContext();

  const [containerWidth, setContainerWidth] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handlers: SwipeableHandlers = useSwipeable({
    onSwipedLeft: () => move(1),
    onSwipedRight: () => move(-1),
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  const containerRef = useRef<HTMLDivElement | null>(
    null
  ) as React.MutableRefObject<HTMLDivElement | null>;

  const updateContainerWidth = useCallback(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);
    return () => window.removeEventListener("resize", updateContainerWidth);
  }, [updateContainerWidth]);

  const calculateTranslateX = useCallback(
    (index: number) => {
      const sizeKey = containerWidth >= 640 ? "tablet" : "mobile";
      return (
        containerWidth / 2 -
        ITEM_WIDTH[sizeKey] / 2 -
        index * (ITEM_WIDTH[sizeKey] + ITEM_GAP[sizeKey])
      );
    },
    [containerWidth]
  );

  const move = (direction: number): void => {
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < totalItems) {
      setCurrentIndex(newIndex);
    } else if (!isAnimating) {
      setIsAnimating(true);
      const currentTranslateX = calculateTranslateX(currentIndex);
      const springTranslateX =
        currentTranslateX + -1 * direction * SPRING_MOVEMENT;

      if (containerRef.current) {
        containerRef.current.style.transition = "transform 0.45s";
        containerRef.current.style.transform = `translateX(${springTranslateX}px)`;

        setTimeout(() => {
          if (containerRef.current) {
            containerRef.current.style.transition =
              "transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)";
            containerRef.current.style.transform = `translateX(${currentTranslateX}px)`;
          }
          setTimeout(() => setIsAnimating(false), 400);
        }, 450);
      }
    }
  };

  const translateX = calculateTranslateX(currentIndex);

  const combinedRef = useCallback(
    (node: HTMLDivElement | null) => {
      containerRef.current = node;
      handlers.ref(node);
      if (node) {
        updateContainerWidth();
      }
    },
    [handlers, updateContainerWidth]
  );

  return (
    <div className="relative w-full">
      <div className="overflow-hidden max-w-full -my-8">
        <motion.div
          className="flex gap-[10px] sm:gap-5 my-8 transition-transform duration-[450ms] ease-in-out"
          style={{ transform: `translateX(${translateX}px)` }}
          {...handlers}
          ref={combinedRef}
        >
          {items.map((item, index) => (
            <div key={index} className="flex-none">
              {item}
            </div>
          ))}
        </motion.div>
      </div>
      <button
        className="absolute arrow-button-shadow rounded-full bg-[#141416] w-12 h-12 sm:w-14 sm:h-14 -bottom-[106px] left-[calc(50%-165px)] sm:left-[calc(50%-178px)] inline-block"
        disabled={isAnimating}
        onClick={() => move(-1)}
      >
        <LeftIcon className="mx-auto w-8 h-8 sm:w-auto sm:h-auto" />
      </button>
      <button
        className="absolute arrow-button-shadow rounded-full bg-[#141416] w-12 h-12 sm:w-14 sm:h-14 -bottom-[106px] left-[calc(50%+109px)] sm:left-[calc(50%+122px)] inline-block"
        disabled={isAnimating}
        onClick={() => move(1)}
      >
        <RightIcon className="mx-auto w-8 h-8 sm:w-auto sm:h-auto" />
      </button>
    </div>
  );
};

export default HorizontalList;
