import { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../images/icon-cancel-l.svg";

interface FullscreenImageProps {
  src: string | null;
  close: () => void;
}

export default function FullscreenImage({ src, close }: FullscreenImageProps) {
  const [shouldRender, setShouldRender] = useState(false);
  const [animationClass, setAnimationClass] = useState("opacity-0 scale-50");

  useEffect(() => {
    if (src) {
      setShouldRender(true);
      // Trigger the animation after a short delay to ensure the initial class is applied
      setTimeout(
        () => setAnimationClass("opacity-100 scale-100 duration-[450ms]"),
        50
      );
    } else {
      setAnimationClass("opacity-0 scale-50 duration-[200ms]");
      const timer = setTimeout(() => setShouldRender(false), 200);
      return () => clearTimeout(timer);
    }
  }, [src]);

  if (!shouldRender) {
    return null;
  }

  return (
    <div
      className={`fixed inset-0 bg-black z-50 transform transition-all ease-in-out origin-[50%_20%] ${animationClass}`}
      style={{ opacity: src ? 1 : 0 }}
    >
      {src && (
        <img
          src={src}
          alt="fullscreen"
          className="object-contain h-full max-w-full mx-auto aspect-square"
        />
      )}
      <button onClick={close} className="absolute top-6 right-6">
        <CloseIcon />
      </button>
    </div>
  );
}
