import ButtonBase from "./ButtonBase";
import { ReactComponent as CheckIcon } from "../../images/icon-check-l.svg";
import { ButtonHTMLAttributes } from "react";

export default function CheckButton({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  const getClassName = (isPressed: boolean) =>
    isPressed ? "bg-[#4F0FE9] !shadow-none" : "bg-[#7121F8]";

  return (
    <ButtonBase {...props} className="p-6" getClassName={getClassName}>
      <CheckIcon />
    </ButtonBase>
  );
}
