import { useEffect, useRef, useState } from "react";
import { useListContext } from "./ListContext";
import CardContainer from "./CardContainer";
import { getImage, saveImage } from "../utils/indexedDBUtils";
import { compressImage } from "../utils/imageCompressor";

import { ReactComponent as RetryIcon } from "../images/icon-retry.svg";
import { ReactComponent as SaveIcon } from "../images/icon-save.svg";
import { ReactComponent as BubbleImg } from "../images/prompt-bubble.svg";

const imageSetPaths = [
  [
    "generating/img-generating-a-01.png",
    "generating/img-generating-a-02.png",
    "generating/img-generating-a-03.png"
  ],
  [
    "generating/img-generating-b-01.png",
    "generating/img-generating-b-02.png",
    "generating/img-generating-b-03.png"
  ],
  [
    "generating/img-generating-c-01.png",
    "generating/img-generating-c-02.png",
    "generating/img-generating-c-03.png"
  ],
  [
    "generating/img-generating-d-01.png",
    "generating/img-generating-d-02.png",
    "generating/img-generating-d-03.png"
  ]
];

interface DrawingCardProps {
  index: number;
  drawing: any;
  redraw: (drawing: any) => void;
  showImageFullscreen: (src?: string) => void;
  isRedrawable: boolean;
}

const toProxyUrl = (url: string) => {
  return url.replace(
    "https://oaidalleapiprodscus.blob.core.windows.net",
    "/image"
  );
};

export default function DrawingCard({
  index,
  drawing,
  redraw,
  showImageFullscreen,
  isRedrawable
}: DrawingCardProps) {
  const { currentIndex, setCurrentImageSrc } = useListContext();

  const [imageSrc, setImageSrc] = useState<string>();
  const imgRef = useRef<HTMLImageElement>(null);
  const blobRef = useRef<Blob | null>(null);

  const gerenatingImageSetIndex = useRef(
    Math.floor(Math.random() * imageSetPaths.length)
  );

  useEffect(() => {
    const loadImage = async () => {
      try {
        blobRef.current = await getImage(drawing.id);
        if (blobRef.current) {
          const objectUrl = URL.createObjectURL(blobRef.current);
          setImageSrc(objectUrl);
        } else {
          setImageSrc(toProxyUrl(drawing.imageUrl));
          handleSave();
        }
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    const handleSave = async () => {
      try {
        const response = await fetch(toProxyUrl(drawing.imageUrl));
        const blob = await response.blob();
        const compressedBlob = await compressImage(blob);
        blobRef.current = compressedBlob || blob;

        await saveImage(drawing.id, blobRef.current);
      } catch (error) {
        console.error("Error saving image:", error);
      }
    };

    if (drawing.id) {
      loadImage();
    }

    return () => {
      if (imageSrc?.startsWith("blob:")) {
        URL.revokeObjectURL(imageSrc);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawing.id]);

  useEffect(() => {
    if (currentIndex === index) {
      setCurrentImageSrc(imageSrc || null);
    }
  }, [currentIndex, index, imageSrc, setCurrentImageSrc]);

  if (!drawing.id) {
    return (
      <CardContainer>
        <div className="px-8 text-center">
          <p className="text-center font-bold mt-16 leading-4 sm:mt-[72px] sm:leading-5 text-[#7121F8]">
            잠시만 기다려 주세요
          </p>
          <p className="sm:text-[28px] text-2xl text-[#141416] sm:leading-8 text-center font-bold mt-2 sm:mt-3">
            그림을 그리고 있어요
          </p>
          <div className="sm:mt-4 sm:h-[202px] relative">
            <BubbleImg className="sm:w-auto w-60" />
            <div className="overflow-hidden text-sm text-[#3D3F40] mx-2 sm:mx-10 rounded-[72px] bg-[#F1E9FE] sm:text-lg text-center absolute inset-x-0 top-11 sm:top-6 h-[85px] sm:h-[104px] flex items-center justify-center">
              {drawing.prompt}
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0">
          <DrawingCharacterCarousel
            imageSetIndex={gerenatingImageSetIndex.current}
          />
        </div>
      </CardContainer>
    );
  }

  const onClickRedraw = () => {
    redraw(drawing);
  };

  const downloadImage = async (filename: string) => {
    if (!imageSrc) return;

    try {
      let url = imageSrc;
      if (!url.startsWith("blob:")) {
        if (!blobRef.current) {
          const response = await fetch(toProxyUrl(imageSrc));
          blobRef.current = await response.blob();
        }
        url = window.URL.createObjectURL(blobRef.current);
      }

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  const onClickSave = () => {
    downloadImage(`my-image-${Date.now()}.png`);
  };

  return (
    <CardContainer>
      <div className="h-full p-2.5 sm:px-4 sm:pt-4 sm:pb-3 flex flex-col">
        <img
          className="aspect-square w-full rounded-xl cursor-pointer"
          ref={imgRef}
          src={imageSrc}
          alt={drawing.prompt}
          sizes="400px"
          onClick={() => showImageFullscreen(imageSrc)}
        />
        <div className="flex flex-1 items-center justify-between gap-2 sm:gap-3">
          <button
            className="card-button"
            onClick={onClickRedraw}
            disabled={!isRedrawable}
          >
            <RetryIcon fill={isRedrawable ? "#F3403C" : "#989B9C"} />
          </button>
          <div className="sm:text-lg leading-6 text-[#141416] text-center flex-1 font-prompt">
            {drawing.prompt}
          </div>
          <button className="card-button" onClick={onClickSave}>
            <SaveIcon />
          </button>
        </div>
      </div>
    </CardContainer>
  );
}

const DrawingCharacterCarousel = ({
  imageSetIndex
}: {
  imageSetIndex: number;
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageSources, setImageSources] = useState<string[]>([]);

  useEffect(() => {
    const loadImages = async () => {
      const sources = await Promise.all(
        imageSetPaths[imageSetIndex].map(async path => {
          const module = await import(`../images/${path}`);
          return module.default;
        })
      );
      setImageSources(sources);
    };

    loadImages();
  }, [imageSetIndex]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % 3);
    }, 400);

    return () => clearInterval(intervalId);
  }, []);

  if (imageSources.length === 0) {
    return null;
  }

  return <img src={imageSources[currentImageIndex]} alt="drawing" />;
};
