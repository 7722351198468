import ButtonBase from "./ButtonBase";
import { ReactComponent as PlayIcon } from "../../images/ic-play.svg";
import { ReactComponent as PauseIcon } from "../../images/ic-pause.svg";
import { ButtonHTMLAttributes } from "react";

interface StopButtonProps {
  isPaused: boolean;
}

export default function PlayButton({
  children,
  isPaused,
  ...props
}: StopButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) {
  const getClassName = (isPressed: boolean) =>
    isPressed ? "!shadow-none" : "";

  return (
    <ButtonBase
      {...props}
      className="px-3 pt-[11px] pb-[13px] bg-[#141416]"
      getClassName={getClassName}
    >
      {isPaused ? <PlayIcon /> : <PauseIcon />}
    </ButtonBase>
  );
}
