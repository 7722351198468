import React, { useEffect } from "react";
import { useAudioVisualizer } from "./useAudioVisualizer";
import StopButton from "./buttons/StopButton";
import RerecordButton from "./buttons/RerecordButton";
import PlayButton from "./buttons/PlayButton";

interface AudioVisualizerProps {
  onRecorded: (blob: Blob) => void;
  onError: (error: string) => void;
  retry: () => void;
  maxRecordingTime?: number;
  width?: number;
  height?: number;
}

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({
  onRecorded,
  onError,
  retry,
  maxRecordingTime,
  width = 706,
  height = 64
}) => {
  const {
    isRecording,
    isPlaying,
    canvasRef,
    audioRef,
    recordedBlob,
    error,
    startRecording,
    stopRecording,
    playAudio,
    pauseAudio,
    handleAudioEnded
  } = useAudioVisualizer({ maxRecordingTime, width, height });

  useEffect(() => {
    startRecording();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!recordedBlob) {
      return;
    }

    onRecorded(recordedBlob);
  }, [recordedBlob, onRecorded]);

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  return (
    <div style={{ width: `${width}px`, margin: "0 auto" }}>
      <canvas ref={canvasRef} width={width} height={height} />
      <audio ref={audioRef} onEnded={handleAudioEnded} />
      <div className="flex items-center justify-center gap-5 mt-16">
        {isRecording && <StopButton onClick={stopRecording} />}
        {!isRecording && recordedBlob != null && (
          <RerecordButton onClick={retry} />
        )}
        {!isRecording && recordedBlob != null && (
          <PlayButton
            onClick={isPlaying ? pauseAudio : playAudio}
            isPaused={!isPlaying}
          />
        )}
      </div>
    </div>
  );
};

export default AudioVisualizer;
